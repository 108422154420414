import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";
import axios from "axios";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LearningElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 57px 20px 52px 20px;
  background: rgba(48, 52, 64, 1);

  .cmp-learning-container {
    max-width: 1154px;
    width: 100%;
  }

  .cmp-learning-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
   
    @media (max-width: 730px) {
      flex-direction: column;
    }

    .cmp-learning-header-content {
      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: #fff;
        margin-right: 40px;

        @media (max-width: 730px) {
          margin-right: unset;
          margin-bottom: 20px;
        }
      }
    }

    ul.cmp-learning-tabs {
      display: flex;

      @media (max-width: 730px) {
        order: 3;
      }

      li {
        &:not(:last-child) {
          margin-right: 53px;
        }

        button {
          background: none;
          border: none;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.36px;
          color: #c4c5c8;
          padding: 0;

          span {
            color: #dc6126;
          }

          &:hover {
            color: #fff;
            cursor: pointer;
          }

          &.active {
            color: #fff;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              height: 3px;
              left: 0;
              right: 0;
              bottom: -10px;
              background: #dc6126;
            }
          }
        }
      }
    }

    a {
      @media (max-width: 730px) {
        order: 2;
        margin-left: unset;
        margin-bottom: 40px;
      }

      margin-left: auto;
      border-radius: 30px;
      background: #dc6126;
      border: 2px solid #dc6126;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;
    }
  }

  .cmp-learning-card {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 365px;
    width: 100%;
    height: 240px;
    padding: 26px 24px 21px 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    background: #fff;
    box-sizing: border-box;

    &.empty{
      height:172px!important;
      background-color:#DC6126!important;
     
      .cmp-learning-card-title{
        color:#fff!important;
      }
      .cmp-learning-card-link{
        color:#DC6126!important;
      }
      .cmp-learning-card-link:hover {
       border-color:white!important;
       color:white!important; 
      }
      .cmp-learning-card-title {
        max-width:100%!important;
      }
    }

    .cmp-learning-card-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .cmp-learning-type{
        color:#DC6126;
        letter-spacing: -0.14px;
        font: normal normal bold 14px/19px Roboto;
        display: flex; /* Use flexbox for vertical centering */
        align-items: center; /* Vertically center the content */
        margin-bottom:5px;

        img{
          width: 18px; /* Adjust the width of the icon as per your requirement */
          height: 18px; /* Adjust the height of the icon as per your requirement */
          margin-right: 5px; /* Add some space between the icon and text */
        }
  
      }
      .cmp-learning-card-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        color: #000;
        max-width: 264px;
        margin-bottom:5px;
      }

      
    }

    .cmp-learning-card-footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      
      .cmp-learning-card-link {
        border-radius: 30px;
        background: #fff;
        border: 2px solid #dc6126;
        text-decoration: none;
        color: #242731;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0;
        padding: 6px 26px;
        font-weight: 700;
        margin-top: 15px;
      }
      .cmp-learning-card-link:hover {
        background-color: #dc6126; /* Change the background color on hover */
        color: #fff; /* Change the text color on hover */
        cursor: pointer;
        
      }
     

      .cmp-learning-card-due-date {
        font-size: 14px;
        line-height: 16px;
      }

      .cmp-learning-card-course-length {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.48px;
        color: #000000;
        font-weight: 700;
        margin-left: auto;
      }
    }
  }

  .cmp-learning-assignments {
    .swiper-wrapper {
      height: 830px;

      @media (min-width: 769px) {
        height: 562px;
      }

      @media (max-width: 768px) {
        height: 560px;
      }
    }

    .swiper-slide {
      height: unset;
    }

    .swiper-pagination {
      bottom: 0px;

      .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        background: none;
        border: 2px solid #989aa0;
        opacity: 1;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &.swiper-pagination-bullet-active {
          background: #fff;
          border: 2px solid #fff;
        }
      }
    }
  }

  /* .cmp-learning-assignments.cmp-learning-assignments-with-slider {
    @media (max-width: 639px) {
      display: none;
    }
  } */

  .cmp-learning-assignments.cmp-learning-assignments-mobile {
    @media (min-width: 641px) {
      display: none;
    }
    .cmp-learning-card {
      max-width: 100%;
      height: 180px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .empty-state {
    padding: 24px 0;

    p {
      color: #fff;
      line-height: 21px;
    }

    @media (max-width: 768px) {
      padding: 20px;

      p {
        text-align: center;
        padding: 0 20px;
      }
    }
  }

  .loading-container {
    display: flex;

    .loading-card {
      height: 240px;
      width: 100%;
      background: #fff;
      border-radius: 7px;
      padding: 26px 24px 21px;
      display: flex;
      flex-direction: column;
      box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .loading-card-footer {
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        div {
          width: 35%;
        }
      }
    }
  }
`;

const Learning = () => {
  const [loading, setLoading] = useState(true);
  const [learningData, setLearningData] = useState(null);
  const [activeTab, setActiveTab] = useState("available");
  const [courses, setCourses] = useState(null);

  const { cmpData, environment, cuesURL, cmanURL, degreedURL } = useAuth();
  const icnCourse = require("../../assets/images/icn_courses@2x.png");
  const icnJourney = require("../../assets/images/icn_journeys@2x.png");
  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }
  function truncateStringWithPTag(inputString, maxLength) {
    if (inputString === undefined || inputString === null)
      return inputString;
    // Regular expression to match <p></p> tags and their content
    const regex = /(<([^>]+)>)/gi;

    // Replace all <p></p> tags with an empty string
    const stringWithoutPTags = inputString.replace(regex, '');
    console.log(stringWithoutPTags)
    // If there are no <p></p> tags, return the original input string
    if (!stringWithoutPTags.trim()) {
      return inputString;
    }

    // Truncate the resulting string to the specified maxLength
    if (stringWithoutPTags.length > maxLength) {
      const lastSpaceIndex = stringWithoutPTags.lastIndexOf(' ', maxLength);
      if (lastSpaceIndex !== -1) {
        return stringWithoutPTags.substring(0, lastSpaceIndex) + '...';
      }
      return stringWithoutPTags.substring(0, maxLength) + '...';
    }

    return stringWithoutPTags;
  }


  useEffect(() => {
    let novoedApiUrl;

    if (environment == "local") {
      setLearningData(
        {
          "learning": {
            "enrolled": [
              {
                "user_id": 3260310,
                "course_id": 33543,
                "course_role_id": null,
                "last_activity": "2023-11-10T18:12:52.000Z",
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-10T18:08:11Z",
                "course_home_first_visited_at": "2023-11-10T18:08:11.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-10T18:12:52Z",
                "self_paced_access_end": "2024-02-09T18:08:11.000Z",
                "catalog_id": "novoed-testing-course4",
                "name": "Testing Course 4",
                "type": "Course",
                "release_date": "2023-08-01T13:00:00Z",
                "close_date": "2024-02-27T20:16:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course4\/home",
                "order_date": "2023-11-10T18:08:11Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33544,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-10T18:02:09Z",
                "course_home_first_visited_at": "2023-11-10T18:02:10.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-10T18:02:10Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey7",
                "name": "Testing Journey 7",
                "type": "Journey",
                "release_date": "2023-03-01T13:00:00Z",
                "close_date": "2024-12-15T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey7\/home",
                "order_date": "2023-11-10T18:02:09Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33546,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-10T18:02:34Z",
                "course_home_first_visited_at": "2023-11-10T18:02:37.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-10T18:02:37Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey8",
                "name": "Testing Journey 8",
                "type": "Journey",
                "release_date": "2023-11-01T12:00:00Z",
                "close_date": "2024-01-31T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey8\/home",
                "order_date": "2023-11-10T18:02:34Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33547,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-21T21:01:21Z",
                "course_home_first_visited_at": "2023-11-21T21:01:26.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-21T21:01:26Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey9",
                "name": "Testing Journey 9",
                "type": "Journey",
                "release_date": "2023-11-01T12:00:00Z",
                "close_date": "2024-03-21T18:00:00Z",
                "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EJourney Information\u003C\/p\u003E",
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey9\/home",
                "order_date": "2023-11-21T21:01:21Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33549,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-21T21:02:56Z",
                "course_home_first_visited_at": "2023-11-21T21:02:58.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-21T21:02:58Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey11",
                "name": "Testing Journey 11",
                "type": "Journey",
                "release_date": "2023-01-24T05:00:00Z",
                "close_date": "2024-02-28T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey11\/home",
                "order_date": "2023-11-21T21:02:56Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33551,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-12T16:36:38Z",
                "course_home_first_visited_at": null,
                "completion_progress": "enrolled",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-10-12T16:36:38Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey13",
                "name": "Testing Journey 13",
                "type": "Journey",
                "release_date": "2023-04-01T12:00:00Z",
                "close_date": "2024-04-30T16:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey13\/home",
                "order_date": "2023-10-12T16:36:38Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33555,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-12T16:37:33Z",
                "course_home_first_visited_at": null,
                "completion_progress": "enrolled",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-10-12T16:37:33Z",
                "self_paced_access_end": "2023-10-14T16:37:33.000Z",
                "catalog_id": "novoed-testing-course6",
                "name": "Testing Course 6",
                "type": "Course",
                "release_date": "2023-09-01T12:00:00Z",
                "close_date": "2023-11-30T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course6\/home",
                "order_date": "2023-10-12T16:37:33Z"
              },
              {
                "user_id": 3260310,
                "course_id": 34476,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-10T18:01:26Z",
                "course_home_first_visited_at": "2023-11-10T18:01:31.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-10T18:01:31Z",
                "self_paced_access_end": null,
                "catalog_id": "learningjourney16simple",
                "name": "Learning Journey 16 Simple",
                "type": "Journey",
                "release_date": "2023-10-27T05:00:00Z",
                "close_date": null,
                "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003ETest Learning Journey with fewer educational elements\u003C\/p\u003E",
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/learningjourney16simple\/home",
                "order_date": "2023-11-10T18:01:26Z"
              },
              {
                "user_id": 3260310,
                "course_id": 34983,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-28T17:54:06Z",
                "course_home_first_visited_at": "2023-11-28T17:54:07.000Z",
                "completion_progress": "in_progress",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": null,
                "record_update_at": "2023-11-28T17:54:07Z",
                "self_paced_access_end": "2023-11-28T17:54:06.000Z",
                "catalog_id": "clone-simple-course",
                "name": "Another Testing Course",
                "type": "Course",
                "release_date": "2023-11-15T06:00:00Z",
                "close_date": null,
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/clone-simple-course\/home",
                "order_date": "2023-11-28T17:54:06Z"
              }
            ],
            "completed": [
              {
                "user_id": 3260310,
                "course_id": 33533,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-21T21:34:17Z",
                "course_home_first_visited_at": "2023-11-21T21:34:19.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-11-21T21:34:17Z",
                "record_update_at": "2023-11-21T21:34:19Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey1",
                "name": "Testing Journey 1",
                "type": "Journey",
                "release_date": "2023-08-01T12:00:00Z",
                "close_date": "2024-03-27T16:00:00Z",
                "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey1\/home",
                "order_date": "2023-11-21T21:34:17Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33534,
                "course_role_id": null,
                "last_activity": "2023-10-25T20:01:01.000Z",
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T19:56:03Z",
                "course_home_first_visited_at": "2023-10-25T19:56:03.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:01:01Z",
                "record_update_at": "2023-10-25T20:01:01Z",
                "self_paced_access_end": "2023-12-28T19:56:03.000Z",
                "catalog_id": "novoed-testing-course1",
                "name": "Testing Course 1",
                "type": "Course",
                "release_date": "2023-08-01T12:00:00Z",
                "close_date": "2024-01-31T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course1\/home",
                "order_date": "2023-10-25T19:56:03Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33535,
                "course_role_id": null,
                "last_activity": "2023-10-25T20:36:04.000Z",
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T20:33:11Z",
                "course_home_first_visited_at": "2023-10-25T20:33:11.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:36:04Z",
                "record_update_at": "2023-10-25T20:36:04Z",
                "self_paced_access_end": "2023-10-25T20:33:11.000Z",
                "catalog_id": "novoed-testing-course2",
                "name": "Testing Course 2",
                "type": "Course",
                "release_date": "2023-08-01T12:00:00Z",
                "close_date": "2023-11-26T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course2\/home",
                "order_date": "2023-10-25T20:33:11Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33536,
                "course_role_id": null,
                "last_activity": "2023-10-25T20:03:45.000Z",
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T20:01:16Z",
                "course_home_first_visited_at": "2023-10-25T20:01:17.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:03:45Z",
                "record_update_at": "2023-10-25T20:03:45Z",
                "self_paced_access_end": "2023-10-25T20:01:16.000Z",
                "catalog_id": "novoed-testing-course3",
                "name": "Testing Course 3",
                "type": "Course",
                "release_date": "2023-08-01T13:00:00Z",
                "close_date": "2023-11-27T16:00:00Z",
                "class_summary": "\u003Cp\u003EExample course description. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel tortor id diam faucibus hendrerit at nec arcu. Nam dignissim eleifend ultrices. Sed rhoncus massa sed neque consectetur, et varius arcu laoreet.\u003C\/p\u003E",
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-course3\/home",
                "order_date": "2023-10-25T20:01:16Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33539,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T19:55:46Z",
                "course_home_first_visited_at": "2023-10-25T19:55:48.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:03:45Z",
                "record_update_at": "2023-10-25T20:03:45Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey4",
                "name": "Testing Journey 4",
                "type": "Journey",
                "release_date": "2023-06-01T12:00:00Z",
                "close_date": "2024-03-28T16:00:00Z",
                "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description...\u003C\/p\u003E",
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey4\/home",
                "order_date": "2023-10-25T19:55:46Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33540,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T20:15:12Z",
                "course_home_first_visited_at": "2023-10-25T20:15:13.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:15:12Z",
                "record_update_at": "2023-10-25T20:15:13Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey5",
                "name": "Testing Journey 5",
                "type": "Journey",
                "release_date": "2023-05-01T12:00:00Z",
                "close_date": "2024-02-29T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey5\/home",
                "order_date": "2023-10-25T20:15:12Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33541,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-10-25T20:23:08Z",
                "course_home_first_visited_at": "2023-10-25T20:32:07.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-10-25T20:36:04Z",
                "record_update_at": "2023-10-25T20:36:04Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey6",
                "name": "Testing Journey 6",
                "type": "Journey",
                "release_date": "2023-04-01T12:00:00Z",
                "close_date": "2024-12-10T17:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey6\/home",
                "order_date": "2023-10-25T20:23:08Z"
              },
              {
                "user_id": 3260310,
                "course_id": 33552,
                "course_role_id": null,
                "last_activity": null,
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-28T15:37:15Z",
                "course_home_first_visited_at": "2023-11-28T15:37:22.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-11-28T15:37:15Z",
                "record_update_at": "2023-11-28T15:37:22Z",
                "self_paced_access_end": null,
                "catalog_id": "novoed-testing-journey14",
                "name": "Testing Journey 14 for testing",
                "type": "Journey",
                "release_date": "2023-05-01T12:00:00Z",
                "close_date": "2024-05-30T16:00:00Z",
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey14\/home",
                "order_date": "2023-11-28T15:37:15Z"
              },
              {
                "user_id": 3260310,
                "course_id": 34472,
                "course_role_id": null,
                "last_activity": "2023-11-10T18:17:53.000Z",
                "role": "No Role",
                "is_course_admin": false,
                "enrolled": true,
                "enrolled_at": "2023-11-10T18:16:12Z",
                "course_home_first_visited_at": "2023-11-10T18:16:13.000Z",
                "completion_progress": "completed",
                "is_mentor": false,
                "unenrolled_at": null,
                "completed_at": "2023-11-10T18:18:57Z",
                "record_update_at": "2023-11-10T18:18:57Z",
                "self_paced_access_end": "2023-11-10T18:16:12.000Z",
                "catalog_id": "testingcourse8easy",
                "name": "Testing Course 9 Simple Journey",
                "type": "Course",
                "release_date": "2023-10-27T05:00:00Z",
                "close_date": null,
                "class_summary": null,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/testingcourse8easy\/home",
                "order_date": "2023-11-10T18:16:12Z"
              }
            ],
            "available": [
              {
                "id": 33537,
                "catalog_id": "novoed-testing-journey2",
                "name": "Testing Journey 2",
                "release_date": "2023-09-01T12:00:00Z",
                "end_date": null,
                "created_at": "2023-09-27T15:02:47Z",
                "official_release_date": null,
                "registration_close_date": null,
                "close_date": "2024-01-26T17:00:00Z",
                "is_self_paced": null,
                "type_of_registration_in_words": "Open within your organization",
                "class_summary": "\u003Cp class=\u0022froala-style-regular\u0022 dir=\u0022ltr\u0022\u003EExample journey description...\u003C\/p\u003E",
                "enrollment_limit_in_days": null,
                "type": "Journey",
                "used_for": 0,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey2\/home"
              },
              {
                "id": 33548,
                "catalog_id": "novoed-testing-journey10",
                "name": "Testing Journey 10",
                "release_date": "2023-01-01T13:00:00Z",
                "end_date": null,
                "created_at": "2023-09-27T15:56:15Z",
                "official_release_date": null,
                "registration_close_date": null,
                "close_date": "2024-01-30T17:00:00Z",
                "is_self_paced": null,
                "type_of_registration_in_words": "Open within your organization",
                "class_summary": null,
                "enrollment_limit_in_days": null,
                "type": "Journey",
                "used_for": 0,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey10\/home"
              },
              {
                "id": 33550,
                "catalog_id": "novoed-testing-journey12",
                "name": "Testing Journey 12",
                "release_date": "2023-03-01T13:00:00Z",
                "end_date": null,
                "created_at": "2023-09-27T16:02:38Z",
                "official_release_date": null,
                "registration_close_date": null,
                "close_date": "2024-03-30T16:00:00Z",
                "is_self_paced": null,
                "type_of_registration_in_words": "Open within your organization",
                "class_summary": null,
                "enrollment_limit_in_days": null,
                "type": "Journey",
                "used_for": 0,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey12\/home"
              },
              {
                "id": 33553,
                "catalog_id": "novoed-testing-journey15",
                "name": "Testing Journey 15",
                "release_date": "2023-08-01T12:00:00Z",
                "end_date": null,
                "created_at": "2023-09-27T16:14:01Z",
                "official_release_date": null,
                "registration_close_date": null,
                "close_date": "2024-02-05T17:00:00Z",
                "is_self_paced": null,
                "type_of_registration_in_words": "Open within your organization",
                "class_summary": null,
                "enrollment_limit_in_days": null,
                "type": "Journey",
                "used_for": 0,
                "course_url": "https:\/\/cuessandbox.novoed.com\/#!\/courses\/novoed-testing-journey15\/home"
              }
            ]
          }
        }
      );

      setLoading(false);
    } else {
      novoedApiUrl = cuesURL + "/cmp/novoed"

      axios
        .get(novoedApiUrl)
        .then(function (response) {
          setLearningData(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      switch (activeTab) {
        case "available":

          const rawData = learningData.learning.available;

          if (Array.isArray(rawData)) {
            // Sort the rawData array by the 'title' property
            const sortedData = [...rawData].sort((a, b) => {
              const titleA = a.name.toLowerCase();
              const titleB = b.name.toLowerCase();
              return titleA.localeCompare(titleB);
            });
            setCourses(sortedData);
          } else {
            setCourses([]);
          }

          break;
        case "enrolled":
          let enrolledData = learningData.learning.enrolled;

          if (Array.isArray(enrolledData)) {
            const journeys = enrolledData.filter(item => item.type === "Journey");
            const otherTypes = enrolledData.filter(item => item.type !== "Journey");

            const sortedJourneys = journeys.sort((a, b) => {
              const dateA = new Date(a.order_date);
              const dateB = new Date(b.order_date);
              return dateB - dateA;
            });

            const sortedOtherTypes = otherTypes.sort((a, b) => {
              const getStatus = (item) => {
                if (item.completion_progress === "in_progress" && item.last_activity !== null) {
                  return "in_progress";
                } else if (item.last_activity === null) {
                  return "enrolled";
                } else {
                  return item.completion_progress;
                }
              };
              const statusA = getStatus(a);
              const statusB = getStatus(b);

              // First, compare the statuses
              if (statusA === "in_progress" && statusB === "enrolled") {
                return -1; // "in_progress" comes before "enrolled"
              } else if (statusA === "enrolled" && statusB === "in_progress") {
                return 1; // "enrolled" comes after "in_progress"
              } else {
                // If the statuses are the same or neither is "in_progress," compare by date in descending order
                const dateA = new Date(a.order_date);
                const dateB = new Date(b.order_date);
                return dateB - dateA;
              }
            });

            const enrolledSortedData = [...sortedJourneys, ...sortedOtherTypes];
            setCourses(enrolledSortedData);
          } else {
            setCourses([]);
          }

          break;
        case "completed":
          let completedData = learningData.learning.completed;

          if (Array.isArray(completedData)) {
            // Sort the enrolledData array by both 'order_date' and 'title' properties
            const completedSortedData = [...completedData].sort((a, b) => {
              // Compare by 'order_date' first
              const dateA = new Date(a.completed_at);
              const dateB = new Date(b.completed_at);
              console.log("dateA:", dateA);
              console.log("dateB:", dateB);
              if (dateA > dateB) {
                return -1;
              } else if (dateA < dateB) {
                return 1;
              } else {
                // If 'order_date' is the same, compare by 'title'
                const titleA = a.name.toLowerCase();
                const titleB = b.name.toLowerCase();
                console.log("titleA:", titleA);
                console.log("titleB:", titleB);
                return titleA.localeCompare(titleB);
              }
            });
            setCourses(completedSortedData);
          } else {
            setCourses([]);
          }

          break;
        default:
          console.log("I don't know what courses to show...");
      }
    }
  }, [loading, activeTab]);



  return (
    <>
      {loading && (
        <LearningElement>
          <div className="cmp-learning-container">
            {/* Header */}
            <div className="cmp-learning-header">
              <div className="cmp-learning-header-content">
                <h2>Learning</h2>
              </div>
            </div>

            <div className="loading-container">
              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>

              <div className="loading-card">
                <div className="loading-card-header">
                  <Skeleton count={3} height={24} />
                </div>

                <div className="loading-card-footer">
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                  <div>
                    <Skeleton count={1} height={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LearningElement>
      )}

      {!loading && learningData && (
        <LearningElement>
          <div className="cmp-learning-container">
            {/* Header */}
            <div className="cmp-learning-header">
              <div className="cmp-learning-header-content">
                <h2>My CUES Learning</h2>
              </div>

              <ul className="cmp-learning-tabs">
                <li>
                  <button
                    className={activeTab == "available" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("available");
                    }}
                  >
                    Available To You
                  </button>
                </li>
                <li>
                  <button
                    className={activeTab == "enrolled" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("enrolled");
                    }}
                  >
                    Enrolled
                  </button>
                </li>
                <li>
                  <button
                    className={activeTab == "completed" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("completed");
                    }}
                  >
                    Completed
                  </button>
                </li>
              </ul>

              <a
                href={"https://cues.novoed.com/#!/users/sign_in"}
                target="_blank"
                className="cmp-clp-visit-button"
                onClick={() => {
                  handleGAEvent("learning_visitportal");
                }}
              >
                Visit CUES Learning Portal
              </a>
            </div>

            {activeTab == "available" && (
              <>
                {/* All Assignments Section */}
                {/* Mobile - No Slider */}
                {/* <div className="cmp-learning-assignments cmp-learning-assignments-mobile">
                  {courses ? (
                    <>
                      {Object.values(courses).map((course) => (
                        <div className="cmp-learning-card">
                          <div className="cmp-learning-card-header">
                            {course.attributes && course.attributes.title && (
                              <p className="cmp-learning-card-title">
                                {course.attributes.title}
                              </p>
                            )}

                            {course.attributes &&
                              course.attributes["degreed-url"] && (
                                <a
                                  href={course.attributes["degreed-url"]}
                                  target="_blank"
                                  className="cmp-learning-card-link"
                                >
                                  View
                                </a>
                              )}
                          </div>

                          <div className="cmp-learning-card-footer">
                            {course["due-at"] && (
                              <p className="cmp-learning-card-due-date">
                                {`Due: ${new Date(
                                  course["due-at"]
                                ).toLocaleDateString()}`}
                              </p>
                            )}

                            {course.attributes &&
                              course.attributes["content-type"] && (
                                <p className="cmp-learning-card-course-length">
                                  {course.attributes["content-type"]}
                                </p>
                              )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="empty-state">
                      <p>
                        Watch for your assignments to appear here as they come
                        in! While you're waiting, you can get started on your
                        own by visiting CUES Learning Portal and completing your
                        profile, which will give you a more personalized
                        learning experience.
                      </p>
                    </div>
                  )}
                </div> */}

                {/* Desktop/Tablet - Slider */}
                <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                  {courses && courses.length > 0 ? (
                    <Swiper
                      simulateTouch={false}
                      slidesPerView={1} // Default slidesPerView
                      slidesPerGroup={1} // Default slidesPerGroup
                      freeMode={false}
                      grid={{
                        rows: 2,
                      }}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Grid, Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        480: {
                          slidesPerView: 1,
                          slidesPerGroup: 1,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                        769: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                      }}
                    >
                      <>
                        {Object.values(courses).map((course) => (
                          <SwiperSlide key={course.course_id}>
                            <div className="cmp-learning-card" data-learning-type={`${course.type}`} data-learning-id={`${course.id}`} data-catalog-id={`${course.catalog_id}`}>
                              <div className="cmp-learning-card-header">
                                <span className="cmp-learning-type"><img src={icnJourney} />Learning Journey</span>
                                {course.name && (
                                  <p className="cmp-learning-card-title">
                                    {course.name}
                                  </p>
                                )}
                                {/* {course.class_summary &&
                                  ( */}

                                <div className="cmp-learning-card-course-length">
                                  {truncateStringWithPTag(course.class_summary, 100)}
                                </div>
                              </div>

                              <div className="cmp-learning-card-footer">
                                {course.course_url && (
                                  <a
                                    href={course.course_url}
                                    target="_blank"
                                    className="cmp-learning-card-link resume"
                                    onClick={() => {
                                      handleGAEvent(
                                        "learning_assigned",
                                        course.name
                                      );
                                    }}
                                  >
                                    View
                                  </a>
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    </Swiper>
                  ) : (
                    <div className="empty-state">
                      <p>
                        You’re currently not enrolled in any learning. Get started on your own by visiting CUES Learning Portal!
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
            {activeTab == "enrolled" && (
              <>


                {/* Desktop/Tablet - Slider */}
                <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                  {courses && courses.length > 0 ? (
                    <Swiper
                      simulateTouch={false}
                      slidesPerView={1}
                      freeMode={false}
                      grid={{
                        rows: 2,
                      }}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Grid, Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        480: {
                          slidesPerView: 1,
                          slidesPerGroup: 1,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                        769: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                      }}
                    >
                      <>
                        {Object.values(courses).map((course) => (
                          <SwiperSlide key={course.course_id}>
                            <div className="cmp-learning-card" data-learning-type={`${course.type}`} data-learning-id={`${course.course_id}`} data-catalog-id={`${course.catalog_id}`}>
                              <div className="cmp-learning-card-header">
                                {course.type && course.type === 'Course' && (
                                  <span className="cmp-learning-type"><img src={icnCourse} />Course</span>
                                )}

                                {course.type && course.type === 'Journey' && (
                                  <span className="cmp-learning-type"><img src={icnJourney} />Learning Journey</span>
                                )}

                                {course.name && (
                                  <p className="cmp-learning-card-title">
                                    {course.name}
                                  </p>
                                )}
                                {/* {course.class_summary &&
                                  ( */}

                                <div className="cmp-learning-card-course-length">
                                  {truncateStringWithPTag(course.class_summary, 100)}
                                </div>
                                {/* )} */}

                              </div>

                              <div className="cmp-learning-card-footer">
                                {course.course_url && (
                                  <a
                                    href={course.course_url}
                                    target="_blank"
                                    className="cmp-learning-card-link resume"
                                    onClick={() => {
                                      handleGAEvent(
                                        "learning_assigned",
                                        course.name
                                      );
                                    }}
                                  >
                                    {course.type === "Journey" && `View`}
                                    {course.type === "Course" && (
                                      <>
                                        {course.completion_progress === "in_progress" && course.last_activity ? `Resume` : `Start`}
                                      </>
                                    )}
                                  </a>
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    </Swiper>
                  ) : (
                    <div className="cmp-learning-card empty">
                      <div className="cmp-learning-card-header">
                        <div className="cmp-learning-card-title">
                          You're currently not enrolled in any learning. Get started on your own by visiting CUES Learning Portal!
                        </div>
                        {/* )} */}

                      </div>

                      <div className="cmp-learning-card-footer">

                        <a
                          href="https://cues.novoed.com/#!/users/sign_in"
                          target="_blank"
                          className="cmp-learning-card-link resume"
                        // onClick={() => {
                        //   handleGAEvent(
                        //     "learning_assigned",
                        //     course.name
                        //   );
                        // }}
                        >
                          Explore Courses
                        </a>

                      </div>
                    </div>

                  )}
                </div>
              </>
            )}
            {activeTab == "completed" && (
              <>
                {/* Completed Assignments Section */}
                {/* Mobile - No Slider */}
                {/* <div className="cmp-learning-assignments cmp-learning-assignments-mobile">
                  {courses ? (
                    <>
                      {Object.values(courses).map((course) => (
                        <div className="cmp-learning-card">
                          <div className="cmp-learning-card-header">
                            {course.attributes && course.attributes.title && (
                              <p className="cmp-learning-card-title">
                                {course.attributes.title}
                              </p>
                            )}

                            {course.attributes &&
                              course.attributes["degreed-url"] && (
                                <a
                                  href={course.attributes["degreed-url"]}
                                  target="_blank"
                                  className="cmp-learning-card-link"
                                >
                                  View
                                </a>
                              )}
                          </div>

                          <div className="cmp-learning-card-footer">
                            {course.attributes &&
                              course.attributes["content-type"] && (
                                <p className="cmp-learning-card-course-length">
                                  {course.attributes["content-type"]}
                                </p>
                              )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="empty-state">
                      <p>
                        Looks like you haven't completed any courses yet—but
                        that's ok, it's easy to get started! Simply visit CUES
                        Learning Portal to complete your profile and add your
                        skills, which will give you a more personalized learning
                        experience.
                      </p>
                    </div>
                  )}
                </div> */}

                {/* Desktop/Tablet - Slider */}
                <div className="cmp-learning-assignments cmp-learning-assignments-with-slider">
                  {courses && courses.length > 0 ? (
                    <Swiper
                      simulateTouch={false}
                      slidesPerView={1}
                      freeMode={false}
                      grid={{
                        rows: 2,
                      }}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Grid, Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        480: {
                          slidesPerView: 2,
                          slidesPerGroup: 4,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                        769: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          // grid: {
                          //   rows: 2,
                          // },
                        },
                      }}
                    >
                      <>
                        {Object.values(courses).map((course) => (
                          <SwiperSlide>
                            <div className="cmp-learning-card" data-learning-type={`${course.type}`} data-learning-id={`${course.course_id}`} data-catalog-id={`${course.catalog_id}`}>
                              <div className="cmp-learning-card-header">

                                {course.type && course.type === 'Course' && (
                                  <span className="cmp-learning-type"><img src={icnCourse} />Course</span>
                                )}

                                {course.type && course.type === 'Journey' && (
                                  <span className="cmp-learning-type"><img src={icnJourney} />Learning Journey</span>
                                )}


                                {course.name && (
                                  <p className="cmp-learning-card-title">
                                    {course.name}
                                  </p>
                                )}

                                {course.class_summary &&
                                  (
                                    <div className="cmp-learning-card-course-length">
                                      {truncateStringWithPTag(course.class_summary, 100)}
                                    </div>
                                  )}
                              </div>

                              <div className="cmp-learning-card-footer">
                                {(course.course_url && (new Date(course.close_date) >= new Date() || course.close_date === null)) && (
                                  <a
                                    href={course.course_url}
                                    target="_blank"
                                    className="cmp-learning-card-link"
                                    onClick={() => {
                                      handleGAEvent(
                                        "learning_assigned",
                                        course.name
                                      );
                                    }}
                                  >
                                    View
                                  </a>
                                )}

                                {course["due-at"] && (
                                  <p className="cmp-learning-card-due-date">
                                    {`Due: ${new Date(
                                      course["due-at"]
                                    ).toLocaleDateString()}`}
                                  </p>
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </>
                    </Swiper>
                  ) : (
                    // <div className="empty-state">
                    //   <p>
                    //     Get started on learning journey in our CUES learning portal
                    //   </p>
                    // </div>
                    <div className="cmp-learning-card empty">
                      <div className="cmp-learning-card-header">

                        <p className="cmp-learning-card-title">
                          Get started on your learning journey in CUES Learning Portal!
                        </p>
                        {/* )} */}

                      </div>

                      <div className="cmp-learning-card-footer">

                        <a
                          href="https://cues.novoed.com/#!/users/sign_in"
                          target="_blank"
                          className="cmp-learning-card-link resume"
                        // onClick={() => {
                        //   handleGAEvent(
                        //     "learning_assigned",
                        //     course.name
                        //   );
                        // }}
                        >
                          Explore Courses
                        </a>

                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </LearningElement>
      )}
    </>
  );
};

export default Learning;
