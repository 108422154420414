import React, { useState, useEffect } from 'react';


const Banner = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        // Check if the screen width is less than or equal to a certain breakpoint (e.g., 768 pixels)
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        // Add a listener for the window resize event
        window.addEventListener('resize', handleResize);

        // Initial check on component mount
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleHotspotClick = () => {
        const fileUri = 'https://hubs.la/Q02fTXXX0'; // Replace with the actual path to your file
        window.open(fileUri, '_blank');
    };
    const containerStyle = {
        display: 'flex',
        alignItems: 'center', // Center the child vertically
        justifyContent: 'center', // Center the child horizontally
        //height: '100vh', // Set the container's height to the viewport height
        backgroundColor: '#f1f1f1'
        
    };

    const imageContainerStyle = {
        position: 'relative',
    };

    const imageStyle = {
        maxWidth: '100%', // Ensure the image fits within its container
        maxHeight: '100%', // Ensure the image fits within its container
    };

    const hotspotAreaStyle = {
        position: 'absolute',
        top: '40%', // Adjust the top position of the hotspot area as needed
        left: '87%', // Adjust the left position of the hotspot area as needed
        transform: 'translate(-50%, -50%)', // Center the hotspot within the image
        width: '230px', // Adjust the width of the hotspot area as needed
        height: '50px', // Adjust the height of the hotspot area as needed
        backgroundColor: 'transparent', // Make the hotspot area transparent
        cursor: 'pointer', // Change cursor to pointer on hover
        
    };
    const mobileHotspotAreaStyle = {
        position: 'absolute',
        top: '68%', // Adjust the top position of the hotspot area as needed
        left: '70%', // Adjust the left position of the hotspot area as needed
        transform: 'translate(-50%, -50%)', // Center the hotspot within the image
        width: '200px', // Adjust the width of the hotspot area as needed
        height: '50px', // Adjust the height of the hotspot area as needed
        backgroundColor: 'transparent', // Make the hotspot area transparent
        cursor: 'pointer', // Change cursor to pointer on hover
        
    };
    const mobileImageStyle = {
        width: '100%', // Display the image as a square on mobile
        //paddingTop: '100%', // Create a square aspect ratio by setting padding top to 100%
    };
    return (
        <div className="container" style={containerStyle}>
            <div className="image-container" style={imageContainerStyle}>
                {!isMobile ? (
                    <>
                        <img
                            src={require("../../assets/images/cues-cal-bnr-3.png")} // Replace with the actual image source
                            alt="Hotspot Image"
                            className="image" style={{maxHeight:112}}
                        />
                        <div className="hotspot-area" style={hotspotAreaStyle} onClick={handleHotspotClick}></div>
                    </>

                ) : (
                    <>
                        <img
                            src={require("../../assets/images/cues-cal-bnr-m.png")} // Replace with the same image source for mobile
                            alt="Hotspot Image"
                            className="mobile-image"
                            style={mobileImageStyle}
                        />
                        <div className="hotspot-area" style={mobileHotspotAreaStyle} onClick={handleHotspotClick}></div>

                    </>
                )}



            </div>
        </div>
    );
};

export default Banner;

